import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>How To Buy</h2>

          <div class="container-fluid">
            <div class="custom-container">
                <div class="left-portion">
                  <img src="img/create_wallet-200x200.png" className="round-image"/>
                </div>
                <div class="right-portion">
                  <h2>Create a wallet</h2>
                  <p>Download phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to phantom.app.</p>
                </div>
            </div>

            <div class="custom-container">
                <div class="left-portion">
                  <img src="img/buy_solana-200x200.png" className="round-image"/>
                </div>
                <div class="right-portion">
                  <h2>Get some $SOL</h2>
                  <p>Have SOL in your wallet to swap for $9ja. if you don't have any SOL, you can buy directly from phantom, transfer from another wallet, or buy on an exchange and send it to your wallet.</p>
                </div>
            </div>

            <div class="custom-container">
                <div class="left-portion">
                  <img src="img/pumpfun-200x200.jpg" className="round-image"/>
                </div>
                <div class="right-portion">
                  <h2>Go to pump.fun</h2>
                  <p>Connect to pump.fun. Go to pump.fun in google chrome. Connect your wallet. Paste the $9ja token address into pump.fun, select 9ja, and confirm. When phantom prompts you for a wallet signature, sign.</p>
                </div>
            </div>

            <div class="custom-container">
                <div class="left-portion">
                  <img src="img/swap_9ja-200x200.png" className="round-image"/>
                </div>
                <div class="right-portion">
                  <h2>Swap $SOL for $9ja</h2>
                  <p>Swap $SOL for $9ja. We have ZERO taxes so you don't need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.</p>
                </div>
            </div>
          </div>
        </div>
        {/*<div className="row">*/}
        {/*  {props.data*/}
        {/*    ? props.data.map((d, i) => (*/}
        {/*        <div key={`${d.name}-${i}`} className="col-md-4">*/}
        {/*          {" "}*/}
        {/*          <i className={d.icon}></i>*/}
        {/*          <div className="service-desc">*/}
        {/*            <h3>{d.name}</h3>*/}
        {/*            <p>{d.text}</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      ))*/}
        {/*    : "loading"}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
