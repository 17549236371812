import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <p>
                  <a href="https://t.me/+4JNyz9LOP5UxNDE0" aria-label="Go to 9ja telegram group" target="_blank" className="brands">
                    <i className="fa-brands fa-telegram fa-3x"></i>
                  </a>

                  <a href="https://x.com/9ja_coin" aria-label="Go to 9ja twitter(x) profile" target="_blank" className="brands">
                    <i className="fa-brands fa-twitter fa-3x"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
