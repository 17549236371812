import React, { useState } from 'react';

const CopyButton = ({ targetId }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = async () => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            try {
                await navigator.clipboard.writeText(targetElement.innerText);
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        }
    };

    return (
        <button onClick={handleCopy}>
            {isCopied ? 'Copied!' : 'Copy'}
        </button>
    );
};

export default CopyButton;