import { Image } from "./image";
import React from "react";
import CopyButton from "./copyButton";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Tokenomics</h2>

          <section class="tokenomics" id="tokenomics">
            <div class="container">
              <div class="tokens">
                <span><span id="numbers">$9ja_to_the_world_pump</span>
                  <CopyButton targetId="numbers" />
                </span>

            <ul class="tokens__cards">
              <li class="tokens__card">
                {/* <img src="https://placehold.co/270x270" alt="" /> */}
                <img src="img/9ja_eagle_no_taxes_4.png" alt="" />
                <div>
                  <div>
                    <h2>Taxes</h2>
                    <h1>0/0</h1>
                  </div>
                </div>
              </li>
              <li class="tokens__card">
                <img src="img/9ja_eagle_goldmine.png" alt="" />
                <div>
                  <div>
                    <h2>Minting</h2>
                    <h1>Revoked</h1>
                  </div>
                </div>
              </li>
              <li class="tokens__card">
                <img src="img/9ja_eagle_liquidity_burned.png" alt="" />
                <div>
                  <div>
                    <h2>Liquidity</h2>
                    <h1>burned</h1>
                  </div>
                </div>
              </li>
              <li class="tokens__card">
                <img src="img/9ja_eagle_flying.png" alt="" />
                <div>
                  <div>
                    <h2>Total Supply</h2>
                    <h1>1,000,000,000</h1>
                  </div>
                </div>
              </li>
            </ul>

              </div>
            </div>
          </section>

        </div>
        {/*<div className="row">*/}
        {/*  <div className="portfolio-items">*/}
        {/*    {props.data*/}
        {/*      ? props.data.map((d, i) => (*/}
        {/*          <div*/}
        {/*            key={`${d.title}-${i}`}*/}
        {/*            className="col-sm-6 col-md-4 col-lg-4"*/}
        {/*          >*/}
        {/*            <Image*/}
        {/*              title={d.title}*/}
        {/*              largeImage={d.largeImage}*/}
        {/*              smallImage={d.smallImage}*/}
        {/*            />*/}
        {/*          </div>*/}
        {/*        ))*/}
        {/*      : "Loading..."}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
