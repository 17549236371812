import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Unite Nigerians Across The Globe</h2>
        </div>
        {/*<div className="row">*/}
        {/*  {props.data*/}
        {/*    ? props.data.map((d, i) => (*/}
        {/*        <div key={`${d.name}-${i}`} className="col-md-4">*/}
        {/*          <div className="testimonial">*/}
        {/*            <div className="testimonial-image">*/}
        {/*              {" "}*/}
        {/*              <img src={d.img} alt="" />{" "}*/}
        {/*            </div>*/}
        {/*            <div className="testimonial-content">*/}
        {/*              <p>"{d.text}"</p>*/}
        {/*              <div className="testimonial-meta"> - {d.name} </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      ))*/}
        {/*    : "loading"}*/}
        {/*</div>*/}
      </div>
        <div className="container text-center">
            <h4>$9ja has no affiliation with the Nigerian government.</h4>
        </div>
        <div id="footer">
            <div className="container text-center">
                <p>
                    &copy; 2024 9ja. All rights reserved!
                </p>
            </div>
        </div>
    </div>
  );
};
